
import { ref, defineComponent } from 'vue';
import { useRepositories } from "@/repository/repositories";
import type { ElForm } from 'element-plus';
import { validateLoginId, validatePassword } from "@/util/validate";

type FormData = {
  id?: string;
  password?: string;
}
type DataType = {
  formData: FormData;
  failedLogin: boolean;
  showContactDialog: boolean
};


export default defineComponent({
  name: "user-login",

  setup() {
    const ruleFormRef = ref<InstanceType<typeof ElForm>>();
    const repos = useRepositories();
    return {
      ruleFormRef,
      repos,
    }
  },

  data(): DataType {
    return {
      formData: {
        id: undefined,
        password: undefined,
      },
      failedLogin: false,
      showContactDialog: false,
    }
  },
  computed: {
    validateMessage(): (key: string) => string {
      return (key: string): string => {
        switch (key) {
          case "id": {
            if (this.formData.id === undefined) return '';
            const { message } = validateLoginId(this.formData.id);
            return message;
          }
          case "password": {
            if (this.formData.password === undefined) return '';
            const { message } = validatePassword(this.formData.password);
            return message;
          }
          default: {
            throw new Error("unexpected validation target");
          }
        }
      };
    },
  },

  methods: {
    openContactDialog(): void {
      this.showContactDialog = true;
    },

    modifyFormDataForValidate(): void {
      if (this.formData.id === undefined) {
        this.formData.id = "";
      }
      if (this.formData.password === undefined) {
        this.formData.password = "";
      }
    },

    isValidFormData(): boolean {
      let res = validateLoginId(this.formData.id ?? "");
      if (!res.valid) return false;
      res = validatePassword(this.formData.password ?? "");
      if (!res.valid) return false;
      return true;
    },


    async login(): Promise<void> {
      this.modifyFormDataForValidate();
      const res = this.isValidFormData();
      if (!res) {
        throw new Error(`failed validate for login. formData: ${this.formData}`);
      }

      try {
        const token = await this.repos.auth.auth(this.formData.id ?? "", this.formData.password ?? "");
        this.repos.token.save(token);
      } catch (e) {
        console.error(e);
        this.failedLogin = true;
        return;
      }

      this.$router.push({ path: "/" });
    },
  },
});
